import Chart from 'react-apexcharts'

const PieChart = ({ st, count }) => {
    
    const state = {
      series: count,
      options: {
        chart: { width: 380, type: 'pie' },
        labels: st,
        responsive: [{
          breakpoint: 480,
          options: {
            chart: { width: 200 },
            legend: { position: 'bottom' }
          }
        }]
      },
    };

  return (
    <div className="donut">
      { st && count && <Chart id="donut" options={state.options} series={state.series} type="pie" /> }
    </div>
  );
}

export default PieChart;