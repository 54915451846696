/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import SpinnerTTS from "./components/spinner/Spinner";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import getQueryParam from "./assets/getQueryParam";
import Header from "./components/header/Header";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { FieldTimeOutlined, FileTextOutlined, ProjectOutlined } from "@ant-design/icons";
import HomeIcon from "@mui/icons-material/Home";
import REACT_APP from "./environment";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

function App() {
    const { t } = useTranslation();
    const token = sessionStorage.getItem("token") || getQueryParam("user", window.location.search);
    const [wait, setWait] = useState(true);
    const [menu, setMenu] = useState([]);
    const navigate = useNavigate();

    const getAccesses = () => {
        axios(`${REACT_APP.AUTH_API}/api/hrp/v2/employee/accesses`, { headers: { Authorization: token } })
            .then((res) => {
                const x = [];
                x.push({ key: "/", icon: <HomeIcon />, label: res.data.data.home });
                x.push({ key: "/timesheet", icon: <FieldTimeOutlined />, label: t("Timesheet") });
                if (res.data.data.accesses.find((e) => e === "manager")) x.push({ key: "/approval", icon: <FileTextOutlined />, label: t("Approval") });
                if (res.data.data.accesses.find((e) => e === "project_manager"))
                    x.push(
                        { key: "/project", icon: <ProjectOutlined />, label: t("Project") },
                        { key: "/report", icon: <FileTextOutlined />, label: t("Report") }
                    );
                setMenu(x);
                setTimeout(() => setWait(false), 500);
            })
            .catch((err) => {
                setWait(false);
                console.log(err.message);
                toast.error(t("Something went wrong"));
            });
    };

    useEffect(() => {
        if (!token) {
            window.onbeforeunload = {};
            window.location.replace(`${REACT_APP.LOGIN}/login?url=${window.location.href}`);
        } else {
            getAccesses();
            sessionStorage.setItem("token", token);
        }
        navigate(`${window.location.pathname}`);
    }, []);

    i18n.on("languageChanged", () => {
        const items = [menu.find((e) => e.key === "/"), { key: "/timesheet", icon: <FieldTimeOutlined />, label: t("Timesheet") }];
        if (menu.find((e) => e.key === "/approval")) items.push({ key: "/approval", icon: <FileTextOutlined />, label: t("Approval") });
        if (menu.find((e) => e.key === "/project")) items.push({ key: "/project", icon: <ProjectOutlined />, label: t("Project") });
        if (menu.find((e) => e.key === "/report")) items.push({ key: "/report", icon: <FileTextOutlined />, label: t("Report") });
        setMenu(items);
    });

    return wait ? (
        <SpinnerTTS />
    ) : (
        <>
            <ToastContainer />
            <Header item={menu} messages={[]} />
        </>
    );
}

export default App;
