/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Button, CircularProgress, Grid } from "@mui/material";
import { Multiselect } from "multiselect-react-dropdown";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import REACT_APP from "../../environment";
import regex from "tts-hrp-val-lib";
import { useTranslation } from "react-i18next";

const AddProject = (props) => {
    const { t } = useTranslation();
    const { getDate } = props;
    const [selectDepartments, setSelectDepartments] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [loading, setLoading] = useState(false);
    const closeRef = useRef(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const Submit = async (date) => {
        date.departments = selectDepartments;
        setLoading(true);
        axios
            .post(`${REACT_APP.API_URL}/api/sil/v1/project`, date, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setLoading(false);
                reset();
                getDate();
                closeRef.current.click();
                toast.success(t("Successfully saved"));
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("An error occurred"));
            });
    };

    const getDepartment = async () => {
        setLoading(true);
        axios(`${REACT_APP.API_URL}/api/sil/v1/project/department/all`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                setDepartments(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                toast.error(t("An error occurred"));
            });
    };

    useEffect(() => {
        getDepartment();
    }, []);

    return (
        <div className="add_project">
            <Button className="float-end" variant="contained" color="primary" data-bs-toggle="modal" data-bs-target="#addModal">
                {t("Add Project")}
            </Button>
            <div className="modal fade" id="addModal" tabIndex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-3">
                            <div className=" flex-column container">
                                <form onSubmit={handleSubmit(Submit)} className="container py-2 example row g-3">
                                    <Grid className="modal-header py-2 px-0">
                                        <h5>{t("Add Project")}</h5>
                                    </Grid>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item container spacing={3} xs={12} sm="auto" className="pt-4 mt-0">
                                            <Grid item xs={12} sm={6}>
                                                <label className="ms-1 mb-1">{t("Name")}</label>
                                                <input
                                                    type="text"
                                                    placeholder={t("Name")}
                                                    className={`${errors.name ? "is-invalid" : ""} form-control`}
                                                    {...register("name", { required: true, pattern: regex.project_name })}
                                                    disabled={loading}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <label className="ms-1 mb-1">{t("Amount")}</label>
                                                <input
                                                    type="number"
                                                    placeholder={t("Amount")}
                                                    {...register("amount", { required: true })}
                                                    className={`${errors.amount ? "is-invalid" : ""} form-control`}
                                                    disabled={loading}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <label className="ms-1 mb-1">{t("Start Date")}</label>
                                                <input
                                                    type="date"
                                                    placeholder={t("Start Date")}
                                                    {...register("start_date", { required: true })}
                                                    disabled={loading}
                                                    className={`${errors.start_date ? "is-invalid" : ""} form-control`}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <label className="ms-1 mb-1">{t("End Date")}</label>
                                                <input
                                                    type="date"
                                                    placeholder={t("End Date")}
                                                    {...register("end_date", { required: true })}
                                                    disabled={loading}
                                                    className={`${errors.end_date ? "is-invalid" : ""} form-control`}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12}>
                                                <label className="ms-1 mb-1">{t("Select departments")}</label>
                                                <Multiselect
                                                    {...register("departments", { required: !selectDepartments.length })}
                                                    className={`${errors.departments && !selectDepartments.length ? "is-invalid" : ""} form-control p-0`}
                                                    defaultValue={""}
                                                    options={departments}
                                                    placeholder={t("Select departments")}
                                                    emptyRecordMsg={t("There is no other department")}
                                                    displayValue="name"
                                                    value="id"
                                                    closeIcon="circle"
                                                    onSelect={(a) => {
                                                        let ids = [];
                                                        a.map((e) => ids.push(e.id));
                                                        setSelectDepartments(ids);
                                                    }}
                                                    onRemove={(a) => {
                                                        let ids = [];
                                                        a.map((e) => ids.push(e.id));
                                                        setSelectDepartments(ids);
                                                    }}
                                                />
                                            </Grid>

                                            <Grid className="modal-footer mt-4 pt-3 ms-3" item xs={12} sm={12}>
                                                <Button
                                                    className="me-3"
                                                    variant="contained"
                                                    data-bs-dismiss="modal"
                                                    color="error"
                                                    ref={closeRef}
                                                    onClick={() => reset()}
                                                >
                                                    {t("Cancel")}
                                                </Button>
                                                <Button
                                                    className="float-end"
                                                    disabled={loading}
                                                    color="primary"
                                                    type="submit"
                                                    variant="contained"
                                                    startIcon={loading && <CircularProgress size="0.9rem" />}
                                                >
                                                    {loading ? t("Saving") : t("Save")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(AddProject);
