import Chart from "react-apexcharts";

const BarChart = ({ id, count }) => {

    const state = {
      options: {
        chart: { id: "basic-bar" },
        xaxis: { categories: id }
      },
      series: [{ name: "approved", data: count }]
    };

    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart options={state.options} series={state.series} type="bar"/>
          </div>
        </div>
      </div>
    );
}

export default BarChart;