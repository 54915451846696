import React from "react";
import Approval from "../components/Approval";

const ApprovalPage = () => {
    return (
        <div className="px-1 px-lg-4">
            <Approval />
        </div>
    );
};

export default ApprovalPage;
