/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import Article from "./Article";
import "./Department.css";
import ClearIcon from "@mui/icons-material/Clear";
import REACT_APP from "../../environment";
import { useTranslation } from "react-i18next";

const ViewDepartment = ({ getDate, id }) => {
    const { t } = useTranslation();
    const [department, setDepartment] = useState([]);
    const [loading, setLoading] = useState(false);
    const closeRef = useRef(null);

    const getDepartment = async () => {
        setLoading(true);
        axios(`${REACT_APP.API_URL}/api/sil/v1/project/${id}/department`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                setDepartment(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                toast.error(t("An error occurred"));
                setLoading(false);
            });
    };

    useEffect(() => {
        if (id) getDepartment();
    }, [id]);

    return (
        <div className="container">
            <div className="modal fade" id="viewDepartment" tabIndex="-1" aria-labelledby="viewDepartmentLabel" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <article className="modal-content leaderboard">
                        <span className="cencel" ref={closeRef} data-bs-dismiss="modal">
                            <ClearIcon id="clear" />
                        </span>
                        <header>
                            <h3>{t("View Department")}</h3>
                        </header>
                        <main className="leaderboard__profiles">
                            {department.length === 0
                                ? "empty"
                                : department.map((el) => <Article id={id} cancel={closeRef} name={el.name} d_id={el.id} getDate={getDate} />)}
                        </main>
                    </article>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ViewDepartment);
