import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "react-toastify/dist/ReactToastify.css";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
// import { MessageProvider } from "./context/CheckContext";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <>
        {/* to-do: db gets */}
        <I18nextProvider i18n={i18n}>
            {/* <MessageProvider> */}
            <BrowserRouter>
                <App tab="home" />
            </BrowserRouter>
            {/* </MessageProvider> */}
        </I18nextProvider>
    </>
);
