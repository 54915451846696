/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import { Button, Grid } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import REACT_APP from "../../environment";
import regex from "tts-hrp-val-lib";
import { useTranslation } from "react-i18next";

const EditProject = (props) => {
    const { t } = useTranslation();
    const { getDate, setIdx, data } = props;
    const [loading, setLoading] = useState(false);
    const closeRef = useRef(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const Submit = async (date) => {
        date.id = data.id;
        setLoading(true);
        axios
            .put(`${REACT_APP.API_URL}/api/sil/v1/project`, date, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setLoading(false);
                getDate();
                closeRef.current.click();
                toast.success(t("Updated successfully"));
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("An error occurred"));
            });
    };

    const handleCancel = () => {
        setTimeout(() => {
            setIdx("");
        });
    };

    return (
        <div className="container">
            <div className="modal fade" id="editModal" tabIndex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-3">
                            <div className=" flex-column container">
                                <form onSubmit={handleSubmit(Submit)} className="container py-2 example row g-3">
                                    <Grid className="modal-header py-2 px-0">
                                        <h5>{t("Update Project")}</h5>
                                    </Grid>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item container spacing={3} xs={12} sm="auto" className="pt-4 mt-0">
                                            <Grid item xs={12} sm={6}>
                                                <label className="ms-1 mb-1">{t("Name")}</label>
                                                <input
                                                    type="text"
                                                    placeholder={t("Name")}
                                                    defaultValue={data?.name ? data?.name : ""}
                                                    className={`${errors.name ? "is-invalid" : ""} form-control`}
                                                    {...register("name", { required: true, pattern: regex.project_name })}
                                                    disabled={loading}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <label className="ms-1 mb-1">{t("Amount")}</label>
                                                <input
                                                    type="number"
                                                    placeholder={t("Amount")}
                                                    defaultValue={data?.amount ? data?.amount : ""}
                                                    className={`${errors.amount ? "is-invalid" : ""} form-control`}
                                                    disabled={loading}
                                                    {...register("amount", { required: true })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <label className="ms-1 mb-1">{t("Start Date")}</label>
                                                <input
                                                    type="date"
                                                    defaultValue={data.start_date ? data.start_date : ""}
                                                    {...register("start_date", { required: true })}
                                                    disabled={loading}
                                                    className={`${errors.start_date ? "is-invalid" : ""} form-control`}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <label className="ms-1 mb-1">{t("End Date")}</label>
                                                <input
                                                    type="date"
                                                    defaultValue={data.end_date ? data.end_date : ""}
                                                    {...register("end_date", { required: true })}
                                                    disabled={loading}
                                                    className={`${errors.end_date ? "is-invalid" : ""} form-control`}
                                                />
                                            </Grid>

                                            <Grid className="modal-footer mt-4 pt-3 ms-3" item xs={12} sm={12}>
                                                <Button
                                                    className="me-3"
                                                    variant="contained"
                                                    data-bs-dismiss="modal"
                                                    ref={closeRef}
                                                    onClick={handleCancel}
                                                    color="error"
                                                >
                                                    {t("Cancel")}
                                                </Button>

                                                <Button
                                                    className="float-end"
                                                    disabled={loading}
                                                    color="primary"
                                                    type="submit"
                                                    variant="contained"
                                                    startIcon={loading && <CircularProgress size="0.9rem" />}
                                                >
                                                    {loading ? t("Saving") : t("Save")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default React.memo(EditProject);
