import React from "react";
import TimesheetComponent from "../components/timesheet/Timesheet";

const Timesheet = () => {
    return (
        <div className="px-1 px-lg-4">
            <TimesheetComponent />
        </div>
    );
};

export default Timesheet;
