/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress } from "@mui/material";
import REACT_APP from "../../../environment";
import { useNavigate } from "react-router";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import axios from "axios";
import { useTranslation } from "react-i18next";

const ProjectChart = () => {
    const { t } = useTranslation();

    const month = [
        t("January"),
        t("February"),
        t("March"),
        t("April"),
        t("May"),
        t("June"),
        t("July"),
        t("August"),
        t("September"),
        t("October"),
        t("November"),
        t("December"),
    ];
    const [coreStatus, setCoreStatus] = React.useState([]);
    const [coreCount, setCoreCount] = React.useState([]);

    const [coreId, setCoreId] = React.useState([]);
    const [coreIdValue, setCoreIdValue] = React.useState([]);
    const [Loading, setLoading] = React.useState(true);

    const getAmount = async () => {
        axios(`${REACT_APP.API_URL}/api/sil/v1/project/27/chart`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                setCoreStatus(Object.keys(res.data.data));
                setCoreCount(Object.values(res.data.data));
            })
            .catch((err) => toast.error(t("An error occurred")));
    };
    const getMonth = async () => {
        axios(`${REACT_APP.API_URL}/api/sil/v1/project/27/chart/month`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                res.data.data.forEach(async (el) => {
                    setCoreId((prev) => [...prev, month[el.month - 1]]);
                    await axios(`${REACT_APP.API_URL}/api/sil/v1/project/27/chart/${el.year}/${el.month}`, {
                        headers: { Authorization: sessionStorage.getItem("token") },
                    })
                        .then((res) => setCoreIdValue((prev) => [...prev, JSON.stringify(res.data.data.approve)]))
                        .catch((err) => console.log(err));
                });
            })
            .catch((err) => toast.error(t("An error occurred")));
    };

    const getData = async () => {
        setLoading(true);
        await getAmount();
        await getMonth();
        setLoading(false);
    };

    const navigate = useNavigate();
    const onBack = () => navigate("/project");

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="px-xl-5">
            <div className="row">
                <div className="d-flex justify-content-between align-items-center">
                    <Button className="mb-3" variant="contained" color="primary" onClick={onBack}>
                        {t("Go back")}
                    </Button>
                    <h2 className="text-center my-4">{t("Charts")}</h2>
                    <div></div>
                </div>
                {Loading ? (
                    <div className="text-center my-5 py-5">
                        <CircularProgress />
                    </div>
                ) : (
                    <div className="card-body px-xl-5 mx-xl-5">
                        <div className="row g-2">
                            <div className="col-md-6 p-xl-5 text-center">
                                <h4 className="mb-1 mb-sm-4">{t("Amount")}</h4>
                                <PieChart st={coreStatus} count={coreCount} />
                            </div>
                            <div className="col-md-6 p-xl-5 text-center">
                                <h4 className="mb-1 mb-sm-4">{t("By month")}</h4>
                                <BarChart id={coreId} count={coreIdValue} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProjectChart;
