/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import arrow from "../../assets/arrow.png";
import { useEffect, useState } from "react";
import calendarImg from "../../assets/calendar.png";
import axios from "axios";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import "./Timesheet.css";
import Spinner from "react-spinner-material";
import { toast } from "react-toastify";
import REACT_APP from "../../environment";
import regex from "tts-hrp-val-lib";
import { useTranslation } from "react-i18next";

export default function TimesheetComponent() {
    const { t } = useTranslation();
    const [sendDate, setSendDate] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [items, setItems] = useState([]);
    const [updatable, setUpdatable] = useState(false);
    const [projects, setProjects] = useState([]);
    const [offDays, setOffDays] = useState([]);
    const [visible, setVisible] = useState(false);
    const [visibleUpdate, setVisibleUpdate] = useState(false);
    const [visibleSubmit, setVisibleSubmit] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [modalSpinner, setModalSpinner] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [form, setForm] = useState({ project_id: 0, working_hours: "", description: "" });
    const [formUpdate, setFormUpdate] = useState(null);

    useEffect(() => {
        gets();
    }, []);

    useEffect(() => {
        gets();
    }, [currentDate]);

    const gets = async () => {
        setSpinner(true);
        await getOffDays();
        await getProjects();
        await getItems();
        hide();
        hideUpdate();
        hideSubmit();
        setSpinner(false);
    };

    const catchError = (err) => {
        console.log(err);
        toast.error(t("Something went wrong"));
    };

    const getItems = async () => {
        await axios(`${REACT_APP.API_URL}/api/sil/v1/timesheet/week/${getDate(currentDate, 1)}`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                setUpdatable(res.data.data.updatable);
                setItems(res.data.data.items);
            })
            .catch(catchError);
    };

    const getProjects = async () => {
        await axios(`${REACT_APP.API_URL}/api/sil/v1/project/week/${getDate(currentDate, 1)}`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => setProjects(res.data.data))
            .catch(catchError);
    };

    const getOffDays = async () => {
        await axios(`${REACT_APP.API_URL}/api/sil/v1/day/off/${getDate(currentDate, 1)}`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => setOffDays(res.data.data))
            .catch(catchError);
    };

    const prev = () => {
        setUpdatable(false);
        setCurrentDate(new Date(new Date(currentDate).getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7, currentDate.getHours()));
    };
    const next = () => {
        setUpdatable(false);
        setCurrentDate(new Date(new Date(currentDate).getFullYear(), currentDate.getMonth(), currentDate.getDate() + 7, currentDate.getHours()));
    };

    const today = () => {
        setUpdatable(false);
        setCurrentDate(new Date());
    };

    const show = (date) => {
        setSendDate(date);
        setVisible(true);
    };

    const hide = () => {
        setForm({ project_id: 0, working_hours: "", description: "" });
        setVisible(false);
    };

    const showUpdate = (i) => {
        if (updatable) {
            setFormUpdate(i);
            setVisibleUpdate(true);
        }
    };

    const hideUpdate = () => {
        setVisibleUpdate(false);
    };

    const showSubmit = () => {
        setVisibleSubmit(true);
    };

    const hideSubmit = () => {
        setVisibleSubmit(false);
    };

    const create = async () => {
        setModalSpinner(true);
        const hour = form.working_hours.includes("h") ? parseInt(form.working_hours.slice(0, form.working_hours.indexOf("h"))) : 0;
        const minut = form.working_hours.includes("m")
            ? form.working_hours.includes("h")
                ? parseInt(form.working_hours.slice(form.working_hours.indexOf("h ") + 1, form.working_hours.indexOf("m")))
                : parseInt(form.working_hours.slice(0, form.working_hours.indexOf("m")))
            : 0;
        const data = { working_hours_in_minutes: hour * 60 + minut, project_id: form.project_id, date: sendDate };
        if (form.description) data.description = form.description;
        await axios(`${REACT_APP.API_URL}/api/sil/v1/timesheet`, {
            method: "post",
            data: [data],
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then(gets)
            .catch(catchError);
        setModalSpinner(false);
    };

    const update = async () => {
        setModalSpinner(true);
        const hour = formUpdate.working_hours.includes("h") ? parseInt(formUpdate.working_hours.slice(0, formUpdate.working_hours.indexOf("h"))) : 0;
        const minut = formUpdate.working_hours.includes("m")
            ? formUpdate.working_hours.includes("h")
                ? parseInt(formUpdate.working_hours.slice(formUpdate.working_hours.indexOf("h ") + 1, formUpdate.working_hours.indexOf("m")))
                : parseInt(formUpdate.working_hours.slice(0, formUpdate.working_hours.indexOf("m")))
            : 0;
        const data = {
            working_hours_in_minutes: hour * 60 + minut,
            id: formUpdate.id,
            description: formUpdate.description ? formUpdate.description : null,
        };
        await axios(`${REACT_APP.API_URL}/api/sil/v1/timesheet`, {
            method: "put",
            data: data,
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then(gets)
            .catch(catchError);
        setModalSpinner(false);
    };

    const deleteItem = async () => {
        setModalDelete(true);
        await axios(`${REACT_APP.API_URL}/api/sil/v1/timesheet/${formUpdate.id}`, {
            method: "delete",
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then(gets)
            .catch(catchError);
        setModalDelete(false);
    };

    const submit = async () => {
        setModalSpinner(true);
        await axios(`${REACT_APP.API_URL}/api/sil/v1/timesheet/${getDate(currentDate, 1)}/submit`, {
            method: "patch",
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then(() => {
                toast.success(t("Successfully submitted"));
                gets();
            })
            .catch(catchError);
        setModalSpinner(false);
    };

    return (
        <div>
            {(modalDelete || modalSpinner || spinner) && <div className="overlay"></div>}
            <div className="d-flex justify-content-center Timesheet">
                <div className="col-12 table_content">
                    <div className="d-flex justify-content-between align-items-center px-md-3">
                        <img onClick={prev} src={arrow} className="img one" alt="" />
                        <div className="d-flex align-items-center">
                            <h4 id="h4" className="text-center my-3">
                                {getMonth(t, currentDate)} <span style={{ color: "rgba(9, 17, 116, 0.8)" }}>{getYear(currentDate)}</span>
                            </h4>
                            <button id="btn" className="btn" onClick={today}>
                                <img src={calendarImg} alt="" /> {t("Today")}
                            </button>
                        </div>
                        <img onClick={next} src={arrow} className="img" alt="" />
                    </div>
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    {[t("Monday"), t("Tuesday"), t("Wednesday"), t("Thursday"), t("Friday"), t("Saturday"), t("Sunday")].map((e, i) => (
                                        <th>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <div>
                                                    <div className="d_flex">
                                                        <div>{e}</div>
                                                    </div>
                                                    <div style={{ color: "rgba(9, 17, 116, 0.8)" }}>
                                                        {getDay(currentDate, i + 1)} {getMonth(t, currentDate, i)}
                                                    </div>
                                                </div>
                                                <div style={{ textAlign: "right" }}>
                                                    {!offDays.includes(getDate(currentDate, i + 1)) && (
                                                        <>
                                                            {updatable && (
                                                                <i
                                                                    onClick={(e) => show(getDate(currentDate, i + 1))}
                                                                    className="fa-solid fa-circle-plus"
                                                                    style={{ color: "rgba(96, 115, 135, 0.9)" }}
                                                                ></i>
                                                            )}
                                                            <div className="all-hour">
                                                                {timeConvert(
                                                                    items
                                                                        .filter((e) => e.date === getDate(currentDate, i + 1))
                                                                        .reduce((sum, { working_hours_in_minutes }) => sum + working_hours_in_minutes, 0)
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr id="first">
                                    {[1, 2, 3, 4, 5, 6, 7].map((el) => (
                                        <td key={el}>
                                            <div className="t-card">
                                                {items
                                                    .filter((e) => e.date === getDate(currentDate, el))
                                                    .map((i) => (
                                                        <div className="time-item" onClick={() => showUpdate(i)}>
                                                            <div className="ti-box">
                                                                <div className="ti-project">{i.project} </div>
                                                                <div className="ti-hour">{i.working_hours}</div>
                                                            </div>
                                                            {i.description && <div className="ti-desc">{i.description}</div>}
                                                        </div>
                                                    ))}
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <Rodal visible={visible} onClose={hide} height="400">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{t("Add work log")}</h5>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="mb-2 mt-3" style={{ height: "275px" }}>
                                    <div style={{ marginRight: "1rem", marginTop: "3px", width: "100%", maxWidth: "400px" }}>
                                        <span style={{ padding: "1rem auto", fontWeight: "600" }}>{t("project")}:</span>
                                        <select
                                            value={form.project_id}
                                            onChange={(e) => setForm({ ...form, project_id: e.target.value })}
                                            class="border border-gray-300 text-gray-900 text-sm t-input"
                                        >
                                            <option value={0} style={{ display: "none" }}>
                                                {t("select project")}
                                            </option>
                                            {projects.map((e) => (
                                                <option value={e.id}>{e.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div style={{ marginRight: "1rem", marginTop: "3px", width: "100%", maxWidth: "400px" }}>
                                        <span style={{ padding: "1rem auto", fontWeight: "600" }}>{t("hours")}:</span>
                                        <input
                                            value={form.working_hours}
                                            placeholder="2h 30m"
                                            onChange={(e) => setForm({ ...form, working_hours: e.target.value })}
                                            class="border border-gray-300 text-gray-900 text-sm t-input"
                                        />
                                    </div>
                                    <div style={{ marginRight: "1rem", marginTop: "3px", width: "100%", maxWidth: "400px" }}>
                                        <span style={{ padding: "1rem auto", fontWeight: "600" }}>
                                            {t("description")} ({t("optional")}):
                                        </span>
                                        <textarea
                                            value={form.description}
                                            onChange={(e) => setForm({ ...form, description: e.target.value })}
                                            rows={4}
                                            class="border border-gray-300 text-gray-900 text-sm t-input"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={hide}>
                                {t("Close")}
                            </button>
                            <button
                                disabled={modalSpinner || (!!form.project_id && !regex.time_HHh_MMm.test(form.working_hours))}
                                type="button"
                                className="btn btn-primary"
                                style={{ marginLeft: "15px", minWidth: "80px", display: "flex", justifyContent: "center" }}
                                onClick={create}
                            >
                                {modalSpinner ? <Spinner radius={20} color={"#ffffff"} stroke={3} visible={true} /> : t("Save")}
                            </button>
                        </div>
                    </div>
                </Rodal>
                <Rodal visible={visibleUpdate} onClose={hideUpdate} height="400">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{t("Update")}</h5>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="mb-2 mt-3" style={{ height: "275px" }}>
                                    <div style={{ marginRight: "1rem", marginTop: "3px", width: "100%", maxWidth: "400px" }}>
                                        <span style={{ padding: "1rem auto", fontWeight: "600" }}>{t("project")}:</span>
                                        <input value={formUpdate?.project} disabled class="border border-gray-300 text-gray-900 text-sm t-input" />
                                    </div>
                                    <div style={{ marginRight: "1rem", marginTop: "3px", width: "100%", maxWidth: "400px" }}>
                                        <span style={{ padding: "1rem auto", fontWeight: "600" }}>{t("hours")}:</span>
                                        <input
                                            value={formUpdate?.working_hours}
                                            placeholder="2h 30m"
                                            onChange={(e) => setFormUpdate({ ...formUpdate, working_hours: e.target.value })}
                                            class="border border-gray-300 text-gray-900 text-sm t-input"
                                        />
                                    </div>
                                    <div style={{ marginRight: "1rem", marginTop: "3px", width: "100%", maxWidth: "400px" }}>
                                        <span style={{ padding: "1rem auto", fontWeight: "600" }}>
                                            {t("description")} ({t("optional")}):
                                        </span>
                                        <textarea
                                            value={formUpdate?.description}
                                            onChange={(e) => setFormUpdate({ ...formUpdate, description: e.target.value })}
                                            rows={4}
                                            class="border border-gray-300 text-gray-900 text-sm t-input"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn"
                                style={{ minWidth: "80px", display: "flex", justifyContent: "center", color: "white", backgroundColor: "rgb(193, 116, 116)" }}
                                onClick={deleteItem}
                            >
                                {modalDelete ? <Spinner radius={20} color={"#ffffff"} stroke={3} visible={true} /> : t("Delete")}
                            </button>
                            <button type="button" className="btn btn-secondary" style={{ marginLeft: "15px" }} onClick={hideUpdate}>
                                {t("Close")}
                            </button>
                            <button
                                disabled={modalSpinner || (!!formUpdate?.project_id && !regex.time_HHh_MMm.test(formUpdate?.working_hours))}
                                type="button"
                                className="btn btn-primary"
                                style={{ marginLeft: "15px", minWidth: "80px", display: "flex", justifyContent: "center" }}
                                onClick={update}
                            >
                                {modalSpinner ? <Spinner radius={20} color={"#ffffff"} stroke={3} visible={true} /> : t("Save")}
                            </button>
                        </div>
                    </div>
                </Rodal>
            </div>
            {updatable && (
                <>
                    <button type="button" className="btn btn-primary my-3" style={{ float: "right", minWidth: "80px" }} onClick={showSubmit}>
                        {t("Submit")}
                    </button>
                    <Rodal visible={visibleSubmit} onClose={hideSubmit} height="200">
                        <div className="modal-content">
                            <div className="modal-header"></div>
                            <div className="modal-body">
                                <div className="modal-title my-4 mx-2" style={{ fontSize: "1.2rem" }}>
                                    {t("Are you sure you want to submit? After submission, no further changes can be made this week")}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" style={{ marginLeft: "15px" }} onClick={hideSubmit}>
                                    {t("Close")}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    style={{ marginLeft: "15px", minWidth: "80px", display: "flex", justifyContent: "center" }}
                                    onClick={submit}
                                >
                                    {modalSpinner ? <Spinner radius={20} color={"#ffffff"} stroke={3} visible={true} /> : t("Submit")}
                                </button>
                            </div>
                        </div>
                    </Rodal>
                </>
            )}
        </div>
    );
}

const getDate = (date, day) => {
    const deff = new Date(date).getDay() - day;
    const newDate = new Date(new Date(date).getFullYear(), date.getMonth(), date.getDate() - deff, date.getHours());
    return `${new Date(newDate).getFullYear()}-${Math.floor((newDate.getMonth() + 1) / 10)}${(newDate.getMonth() + 1) % 10}-${Math.floor(
        newDate.getDate() / 10
    )}${newDate.getDate() % 10}`;
};

const getDay = (date, day) => {
    const deff = new Date(date).getDay() - day;
    const newDate = new Date(new Date(date).getFullYear(), date.getMonth(), date.getDate() - deff, date.getHours());
    return newDate.getDate();
};

const getYear = (date) => {
    return date.getFullYear();
};

const getMonth = (t, date, day = 0) => {
    switch (new Date(new Date(date).getFullYear(), date.getMonth(), date.getDate() + day - (new Date().getDay() - 1)).getMonth()) {
        case 0:
            return t("January");
        case 1:
            return t("February");
        case 2:
            return t("March");
        case 3:
            return t("April");
        case 4:
            return t("May");
        case 5:
            return t("June");
        case 6:
            return t("July");
        case 7:
            return t("August");
        case 8:
            return t("September");
        case 9:
            return t("October");
        case 10:
            return t("November");
        case 11:
            return t("December");
        default:
            return "";
    }
};

const timeConvert = (time) => {
    const hour = Math.floor(time / 60);
    const minut = time - hour * 60;
    return minut === 0 ? `${hour}h` : hour === 0 ? `${minut}m` : `${hour}h ${minut}m`;
};
