/* eslint-disable no-unused-vars */
import axios from "axios";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import img from "../../assets/avatar.jpg";
import REACT_APP from "../../environment";
import { useTranslation } from "react-i18next";

const Article = (props) => {
    const { t } = useTranslation();
    const { id, d_id, name, getDate, cancel } = props;

    const handleDelete = () => {
        axios
            .delete(`${REACT_APP.API_URL}/api/sil/v1/project/department`, {
                data: { id, department_id: d_id },
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                toast.success(t("Deleted successfully"));
                getDate();
                cancel.current.click();
            })
            .catch((err) => {
                console.log(err);
                toast.error(t("An error occurred"));
            });
    };

    return (
        <article className="leaderboard__profile">
            <img src={img} alt="rr" className="leaderboard__picture" />
            <span className="leaderboard__name">{name}</span>
            <span className="leaderboard__value" onClick={handleDelete}>
                <DeleteIcon className="del" />
            </span>
        </article>
    );
};

export default Article;
