const NODE_ENV = process.env.REACT_APP_ENV || "development";

const modes = {
    development: {
        API_URL: "http://188.166.84.222:9102",
        LOGIN: "http://174.138.2.211:9100",
        AUTH_API: "http://188.166.84.222:9100",
    },
    qa: {
        API_URL: "https://api6-qa.ishkuni.uz",
        LOGIN: "https://auth-qa.ishkuni.uz",
        AUTH_API: "https://api1-qa.ishkuni.uz",
    },
    production: {
        API_URL: "https://api6.ishkuni.uz",
        LOGIN: "https://ishkunim.uz",
        AUTH_API: "https://api1.ishkuni.uz",
    },
};

export default modes[NODE_ENV] || {
    API_URL: "http://188.166.84.222:9102",
    LOGIN: "http://174.138.2.211:9100",
    AUTH_API: "http://188.166.84.222:9100",
};
