/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useForm } from "react-hook-form";
import REACT_APP from "../../environment";
import { useTranslation } from "react-i18next";

const AddDepartment = ({ getDate, id }) => {
    const [department, setDepartment] = useState([]);
    const [loading, setLoading] = useState(false);
    const closeRef = useRef(null);
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const Submit = async (date) => {
        setLoading(true);
        date.id = id;
        axios
            .patch(`${REACT_APP.API_URL}/api/sil/v1/project/department`, date, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setLoading(false);
                reset();
                getDate();
                closeRef.current.click();
                toast.success(t("Successfully saved"));
            })
            .catch((err) => {
                console.log(err.response.data);
                setLoading(false);
                toast.error(t("An error occurred"));
            });
    };

    const getDepartment = async () => {
        setLoading(true);
        axios(`${REACT_APP.API_URL}/api/sil/v1/project/${id}/department/not`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                setDepartment(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                toast.error(t("An error occurred"));
                setLoading(false);
            });
    };

    useEffect(() => {
        if (id) getDepartment();
    }, [id]);

    return (
        <div className="container">
            <div className="modal fade" id="departmentModal" tabIndex="-1" aria-labelledby="departmentModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-3">
                            <div className="flex-column container">
                                <form onSubmit={handleSubmit(Submit)} className="container">
                                    <Grid className="modal-header py-2 px-0">
                                        <h5>{t("Add department")}</h5>
                                    </Grid>
                                    <Grid container direction="column">
                                        <Grid item container spacing={2} xs={12} sm="auto" className="pt-4 mt-0">
                                            <Grid item xs={12} sm={12}>
                                                <select
                                                    disabled={loading}
                                                    {...register("department_id", { required: true })}
                                                    className={`${errors.department_id ? "is-invalid" : ""} form-control`}
                                                >
                                                    <option value="">{t("Select department")}</option>
                                                    {department.map((el) => (
                                                        <option key={el.id} value={el.id}>
                                                            {el.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </Grid>
                                            <Grid className="modal-footer mt-5 pt-3 px-0" item xs={12} sm={12}>
                                                <Button size="small" className="me-3" data-bs-dismiss="modal" variant="contained" color="error" ref={closeRef}>
                                                    {t("Cancel")}
                                                </Button>

                                                <Button
                                                    size="small"
                                                    type="submit"
                                                    className="float-end"
                                                    disabled={loading}
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={loading && <CircularProgress size="0.9rem" />}
                                                >
                                                    {t("Save")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(AddDepartment);
