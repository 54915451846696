/* eslint-disable no-unused-vars */
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { NavLink, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ProjectChart from "../project/project-chart/ProjectChart";
import { Layout, Menu, Button, theme } from "antd";
import React, { useEffect, useState } from "react";
import ApprovalPage from "../../pages/Approval";
import Timesheet from "../../pages/Timesheet";
import Project from "../../pages/Project";
import Report from "../../pages/Report";
import REACT_APP from "../../environment";
import en from "../../assets/en.png";
import uz from "../../assets/uz.png";
import ru from "../../assets/ru.png";
import avatarImg from "../../assets/avatar.jpg";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Notification from "./Notification";

const { Header, Sider, Content } = Layout;

const App = ({ item, messages }) => {
    const [logo, setLogo] = useState(null);
    const [collapsed, setCollapsed] = useState(false);
    const [img, setImg] = useState(null);
    const [sidemodal, setViewSideModal] = useState(false);

    const { t, i18n } = useTranslation();
    const [lang, setlang] = useState(Cookies.get("i18next"));
    const onLanguage = (e) => {
        const lng = e.target.value;
        i18n.changeLanguage(lng);
        setlang(lng);
    };

    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const [selectedKeys, setSelectedKeys] = useState("/personal");

    const location = useLocation();

    useEffect(() => {
        const pathName = location.pathname;
        setSelectedKeys(pathName);
    }, [location.pathname]);

    async function getLogo() {
        await fetch(`${REACT_APP.AUTH_API}/api/hrp/v2/employee/logo`, {
            headers: { Authorization: sessionStorage.getItem("token") },
            method: "GET",
        })
            .then((res) => res.blob())
            .then((blob) => {
                if (blob.type?.slice(0, 5) === "image") {
                    setLogo(window.URL.createObjectURL(blob));
                }
            })
            .catch((err) => console.log(err));
    }
    useEffect(() => {
        getLogo();
    }, []);
    const navigate = useNavigate();

    const logout = () => {
        sessionStorage.clear();
        window.onbeforeunload = {};
        window.location.replace(`${REACT_APP.LOGIN}/login?url=${window.location.href}`);
    };

    return (
        <Layout>
            {sidemodal && <Notification setShow={setViewSideModal} show={sidemodal} messages={messages} />}
            <Sider trigger={null} collapsible collapsed={collapsed} style={{ overflow: "auto", height: "100vh" }}>
                <div className="demo-logo-vertical" />
                <Menu theme="dark" onClick={(item) => navigate(item.key)} mode="inline" selectedKeys={selectedKeys} defaultSelectedKeys={"/"} items={item} />
            </Sider>
            <Layout>
                <Header className="header" style={{ padding: 0, background: colorBgContainer }}>
                    <div>
                        <Button
                            type="text"
                            onClick={() => setCollapsed(!collapsed)}
                            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                            style={{ fontSize: "16px", width: 64, height: 64 }}
                        />
                        {logo && <img src={logo} alt="logo" height={64} />}
                    </div>

                    <div className="d-flex align-items-center">
                        <div className="me-4">
                            <Select value={lang} size="small" onChange={onLanguage} className="bg-slate-300 mr-4">
                                <MenuItem value="en">
                                    <img style={{ width: "23px", marginRight: "7px" }} src={en} alt="en" /> en
                                </MenuItem>
                                <MenuItem value="uz">
                                    <img style={{ width: "23px", marginRight: "7px" }} src={uz} alt="uz" /> uz
                                </MenuItem>
                                <MenuItem value="ru">
                                    <img style={{ width: "23px", marginRight: "7px" }} src={ru} alt="ru" /> ru
                                </MenuItem>
                            </Select>
                        </div>
                        <div className="notification-icon" onClick={() => setViewSideModal(!sidemodal)}>
                            <NotificationsIcon sx={{ fontSize: "28px" }} />
                            {messages.length > 0 && <div className="notification-tooltip">{messages.length}</div>}
                        </div>
                        <div className="dropdown me-4">
                            <a
                                className="dropdown-toggle d-flex align-items-center hidden-arrow"
                                href="#/"
                                id="navbarDropdownMenuAvatar"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <img src={img ? img : avatarImg} className="rounded-circle" style={{ width: "35px", height: "35px" }} alt="avatar" />
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuAvatar">
                                <li>
                                    <NavLink className="avatar-menu-item" to="/login" onClick={logout}>
                                        {t("Logout")}
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Header>
                <Content id="head_content" style={{ background: colorBgContainer, overflow: "auto" }}>
                    <Routes>
                        {item.find((e) => e.key === "/timesheet") && <Route path="/timesheet" element={<Timesheet />} />}
                        {item.find((e) => e.key === "/project") && <Route path="/project" element={<Project />} />}
                        <Route path="/project/:id/chart" element={<ProjectChart />} />
                        {item.find((e) => e.key === "/report") && <Route path="/report" element={<Report />} />}
                        {item.find((e) => e.key === "/approval") && <Route path="/approval" element={<ApprovalPage />} />}
                    </Routes>
                </Content>
            </Layout>
        </Layout>
    );
};

export default App;
